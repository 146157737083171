/**
 * @flow
 */
import { Auth, Logger } from "aws-amplify"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import { Link, navigate } from "gatsby"
import styled from "styled-components"

import { notify } from "../../../state"
import Form from "./form"
import media from "../../../styles/media"
import PageHeader from "../../elements/PageHeader"
import Label from "../../elements/label"

import profile from "./images/profile-icon.svg"
import network from "./images/network-icon.svg"
import votes from "./images/votes-icon.svg"
import bursary from "./images/bursary-icon.svg"
import info from "../../../images/Auth/info-icon.svg"
import underline from "../../../images/Auth/underline_one.png"
import book from "./images/prize_book.png"

type Props = {
  motivational?: boolean,
}

const Container = styled.div`
  position: relative;
  &.red {
    background-color: rgba(196, 18, 48, 0.99);
  }
  &.black {
    background-color: rgba(63, 63, 63, 0.99);
  }
  &.p-left {
    padding-left: var(--gutter-s);
  }
  &.p-left-l {
    padding-left: var(--gutter-l);
  }
  &.p-right {
    padding-right: var(--gutter-s);
  }
  &.p-top,
  &.p-bottom {
    padding-bottom: 10vh;
  }
  ${media.tablet`
    &.p-left {
      padding-left: 10vw;
    }
    &.p-left-l {
      padding-left: 10vw;
    }
    &.p-right {
      padding-right: 5vw;
    }
    &.p-top,
    &.p-bottom {
      padding-bottom: 15vh;
    }
  `}
  ${media.laptop`
    &.p-left {
      padding-left: 25vw;
    }
    &.p-left-l {
      padding-left: 25vw;
    }
    &.p-right {
      padding-right: 20vw;
    }
  `}
`

const Intro = styled.div`
  margin-bottom: 2rem;
  position: relative;
  padding-top: 60px;
  h1,
  p {
    color: rgba(255, 255, 255, 0.8);
  }
  h1 {
    font-family: "AvenirLTStdBlack";
    font-size: 3rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%) rotate(-2deg);
  }
  p {
    font-family: "butlermedium";
    line-height: 1.75rem;
    a {
      text-decoration: underline;
    }
  }
  ${media.laptop`
    padding-top: 80px;
    h1 {
      font-size: 8rem;
    }
  `}
`

const IconList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 0.5rem;
      height: 20px;
      width: auto;
    }
    p {
      font-family: "AvenirLTStdBlack";
      color: rgba(255, 255, 255, 1);
      font-size: 0.8rem;
      text-align: center;
    }
  }
  ${media.laptop`
    .icon {
      img {
        height: 30px;
      }
      p {
        font-size: 0.9rem;
        text-align: left;
      }
    }
  `}
`

const Protection = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2rem;
  img {
    height: 20px;
    width: auto;
    margin-right: 20px;
  }
  p {
    font-family: "AvenirLTStdMedium";
    font-size: 0.8rem;
    line-height: 1rem;
  }
`

const MetaNav = styled.div`
  text-align: center;
  padding-top: 2rem;
  p {
    font-family: "AvenirLTStdMedium";
    font-size: 0.9rem;
    a {
      display: inline-block;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: calc(100% + 20px);
        height: 100%;
        left: -10px;
        bottom: 0;
        transform: translateY(100%);
        background-image: url(${underline});
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
  }
`

const Blurb = styled.div`
  padding-bottom: 200px;
  .book {
    position: absolute;
    right: 25vw;
    top: 100%;
    transform: translateY(-125%);
    img {
      width: auto;
      height: 200px;
    }
    .copy {
      position: absolute;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 1);
      bottom: 0;
      right: 0;
      transform: translate(40%, 40%) rotate(-10deg);
      transform-origin: center center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        font-family: "AvenirLTStdMedium";
        line-height: 1;
        text-align: center;
        color: #fffc00;
        font-size: 0.85rem;
        text-transform: uppercase;
        &.large {
          font-size: 1.5rem;
        }
        &.smallText {
          font-size: 0.5rem;
          text-decoration: underline;
          padding-top: 0.3rem;
        }
      }
    }
  }
  p {
    margin: 1rem 0;
    line-height: 1.75rem;
    font-size: 1rem;
  }
  ol {
    padding: 0 0 0 15px;
    li {
      line-height: 1.75rem;
      font-size: 1rem;
    }
  }
  ${media.tablet`
    padding-bottom: 0;
    p {
    padding-right: 20vw;
    }
    .book {
      position: absolute;
      right: 5vw;
      top: 10%;
      transform: translateY(0);
    }
  `}
  ${media.laptop`
    p {
      padding-right: 0vw;
    }
  `}
`

const SignUpPage = ({ motivational }: Props) => {
  const logger = useMemo(() => new Logger(SignUpPage.name), [])
  const dispatch = useDispatch()

  const handleFormSubmit = async (values, actions) => {
    const { email, password } = values
    try {
      const result = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      })
      logger.info("Sign up success.", result)

      if (result.userConfirmed) {
        const user = await Auth.signIn(email, password)
        logger.info("Sign in success.", user)
        navigate("/", { replace: true })
      } else {
        dispatch(
          notify({
            message: "Your verification code has been sent.",
            variant: "success",
          })
        )
        navigate("/verify", {
          state: { email, password },
        })
      }
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <Container className="red p-left-l p-bottom">
        <PageHeader />
      </Container>
      {!motivational && (
        <Container className="red p-left p-right p-bottom">
          <Blurb>
            <div className="book">
              <img src={book} alt="Sound Idea Sessions | Sign Up" />
              <div className="copy">
                <span>win</span>
                <span className="large">350</span>
                <span>copies</span>
                <span className="smallText">
                  <Link to="/terms" className="mouse-link">
                    t’s and c’s.
                  </Link>
                </span>
              </div>
            </div>
            <Label
              fontSize={1}
              outerRotate={0}
              innerRotate={-1}
              value="Welcome to the Kaya Bizz Growth Hacker Marketing Portal!"
            />
            <p>Are you the ultimate Growth Hacker? Go on. Prove it.</p>
            <p>
              Growth hacking is all about getting your business, music or idea
              to the big leagues without the budget. Want in on this prized
              information?
            </p>
            <p>
              We&#39;re giving away 20 x Sound Ideas Sessions tickets to the
              Growth Hacking Masterclass. Here you can hear Ryan Holiday and
              Black Coffee share their secrets on global domination.
            </p>
            <Label
              fontSize={1}
              outerRotate={0}
              innerRotate={-1}
              value="SIGN UP"
            />
            <p>
              To earn a ticket, you have to show us that you&#39;ve got what it
              takes to be a Growth Hacker. Sign up, share, and start the hustle
              for those votes to climb our leader board. By signing up you agree
              to the Ts&Cs.
            </p>
            <Label
              fontSize={1}
              outerRotate={0}
              innerRotate={-1}
              value="TUNE IN TO KAYA FM"
            />
            <p>
              Sound Idea Sessions is all about combining music and
              thought-leadership, and so who better to partner with than Kaya
              FM. Tune in to the Kaya Bizz weekly, for the Kaya Bizz Growth
              Hacker Marketing Show, featuring Ryan Holiday. On the show you’ll
              get some crib notes on how to take your business from budding to
              booming.
            </p>
            <Label
              fontSize={1}
              outerRotate={0}
              innerRotate={-1}
              value="VERIFY"
            />
            <p>
              Check your inbox. We&#39;ve sent you an email with a verification
              code. Enter it below.
            </p>
            <Label
              fontSize={1}
              outerRotate={0}
              innerRotate={-1}
              value="PROFILE"
            />
            <p>Fill in the form below to complete your profile.</p>
            <Label
              fontSize={1}
              outerRotate={0}
              innerRotate={-1}
              value="LEADERBOARD"
            />
            <p>To climb the leader board:</p>
            <ol>
              <li>Sign up;</li>
              <li>Download the Welcome Pack;</li>
              <li>Share your profile link and hustle for votes.</li>
            </ol>
            <p>
              Our Top 20 Growth Hackers will each receive a ticket to the Sound
              Idea Session Growth Hacking Masterclass.
            </p>
            <p>
              The first 350 to sign up will each receive a copy of Ryan
              Holiday&#39;s book, Growth Hacker Marketing.
            </p>
          </Blurb>
        </Container>
      )}
      <Container className="black p-left p-right p-bottom">
        <Intro>
          <h1>Sign Up</h1>
          <p>
            Start your journey to the Growth Hacker Marketing Masterclass with
            Ryan Holiday and Black Coffee. By signing up you agree to the{" "}
            <Link to="/terms" className="mouse-link">
              t’s and c’s.
            </Link>
          </p>
        </Intro>
        <IconList>
          <div className="icon">
            <img src={profile} alt="Sound Idea Sessions | Sign Up" />
            <p>Create Profile</p>
          </div>
          <div className="icon">
            <img src={network} alt="Sound Idea Sessions | Sign Up" />
            <p>Tell Your Network</p>
          </div>
          <div className="icon">
            <img src={votes} alt="Sound Idea Sessions | Sign Up" />
            <p>Grow your votes</p>
          </div>
          <div className="icon">
            <img src={bursary} alt="Sound Idea Sessions | Sign Up" />
            <p>Win a Ticket</p>
          </div>
        </IconList>
        <Form onSubmit={handleFormSubmit} />
        <Protection>
          <img src={info} alt="Sound Idea Sessions | Sign Up" />
          <p>Your information is protected</p>
        </Protection>
        <MetaNav>
          <p>
            Already have an account? Sign in&nbsp;
            <Link to="/sign-in" className="mouse-link">
              here
            </Link>
          </p>
        </MetaNav>
      </Container>
    </>
  )
}

export default SignUpPage
