import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import SignUpPage from "../components/Auth/SignUpPage"

const SignUp = () => {
  const menuItems = [
    // { name: "Sign In", path: "/sign-in" },
    { name: "Leaderboard", path: "/leaderboard" },
    // { name: "Profiles", path: "/profiles" },
  ]
  return (
    <>
      <SEO title="Sign Up" />
      <Navbar isGHP back items={menuItems} topOffset={0} bottomOffset={125} />
      <SignUpPage />
      <Footer back />
    </>
  )
}

export default SignUp
